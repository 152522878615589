import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ConnectionEvent, useConnectionContext, useConnectionRoom } from "../../providers/ConnectionProvider";
import AppSelector from "../molecules/AppSelector";
import DobbleRender from "../organisms/DobbleRender";
import NicknameInput from "../molecules/NicknameInput";

export default function PlayerRoom({ role = 'player'}) {
  const { dispatch, state: { socket } } = useConnectionContext();
  const { me, room, roomId }= useConnectionRoom();

  const params = useParams();
  
  useEffect(() => {
    if (!params.room) return;
    dispatch({
      type: 'default-room',
      payload: params.room,
    });
    (async () => {
      await socket.emit('profile/update', {
        role,
      })
      await socket.emit('/room/join', {
        roomId: params.room,
      });

    })();

    return () => {
      socket.emit('leave-room', {
        roomId: params.room,
      });
    }
  }, [params.room, socket, dispatch, role]);

  const currentApp = room?.profile?.currentApp;

  return (
    <div>
      {!currentApp && (
        <>
        <div>Seleccione un juego:</div>
          <AppSelector value={currentApp} onChange={(appId) => socket.emit('/room/app.add', {
            roomId,
            appId,
          })}/>
        </>
      )}
      {currentApp === 'dobble' && <DobbleRender />}
    </div>
  );
}
