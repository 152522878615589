import { Link } from "react-router-dom";
// import logo from '../../logo.svg';
// import { useConnectionContext, useConnectionRoom } from "../../providers/ConnectionProvider";
// import { useEffect } from "react";

function Sidebar() {
  // const { state: { socket } } = useConnectionContext();


  // const location = useLocation();

  // useEffect(() => {
  //   socket.emit('/room/join', `path-${location.pathname}`);
  //   return () => socket.emit('leave-room', `path-${location.pathname}`);
  // }, [location.pathname, socket]);

  return (
    <div className="sidebar">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header> */}
      <nav>
        {/* <ul className="menu">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/nothing-here">Nothing Here</Link>
          </li>
        </ul> */}

      </nav>
    </div>
  );
}


export default Sidebar;
