import { get, map, mapValues, mergeWith, omit, pick, set } from "lodash";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { Socket, connect } from 'socket.io-client';
import { chainInmuteSet, inmuteChain, inmuteSet } from "../utils/objects";
import EventEmitter from "events";

interface IRoom {
  id: string;
  profile: {
    currentApp?: string;
  };
  apps: {
    [key: string]: any;
  };
  clients: {
    [key: string]: any;
  };
}

interface IState {
  rooms: {};
  clients: {};
  defaultRoom?: string;
  socket: Socket; 
  emitter: EventEmitter;
}

interface IAction {
  type: string;
  payload: any;
}

const defaultValue = {
  rooms: {},
  clients: {},
  emitter: new EventEmitter(),
  socket: connect(process.env.REACT_APP_API ||  'https://api.hanuji.com', {path: '/ws', transports:['websocket']}),
}

export enum ConnectionEvent {
  RoomProfileUpdate = 'room/profile.update',
  RoomSocketJoin = 'room/socket.join',
  RoomSocketLeave = 'room/socket.leave',
  RoomAppAdd = '/room/app.add',
  AppUpdate = '/app/update',
  ClientDisconnect = 'socket/disconnect',
  ClientProfileUpdate = 'socket/profile.update',
}

const context = createContext<{
  state: IState;
  dispatch: any;
}>({
  state: defaultValue,
  dispatch: undefined,
});

const reducer = (state: IState, action: IAction): IState => {
  console.log(action.type, action.payload);
  switch (action.type) {
    case 'default-room':
      return {
        ...state,
        defaultRoom: action.payload,
      };
    case ConnectionEvent.RoomAppAdd: {
      const { roomId, appId, app } = action.payload;
      return inmuteSet(state, `rooms.${roomId}.apps.${appId}`, app);
    }
    case ConnectionEvent.AppUpdate: {
      const { roomId, appId, ...payload } = action.payload;
      return inmuteChain(state)
        .set(`rooms.${roomId}.apps.${appId}`, payload)
        // .setIf(() => !!status, `rooms.${roomId}.apps.${appId}.status`, status)
        // .setIf(() => !!players, `rooms.${roomId}.apps.${appId}.players`, players, true)
        // .setIf(() => !!screens, `rooms.${roomId}.apps.${appId}.screens`, screens)
        // .setIf(() => !!cards, `rooms.${roomId}.apps.${appId}.cards`, cards, true)
        .value();
    }
    case ConnectionEvent.ClientProfileUpdate: {
      const { socketId, ...profile } = action.payload;
      
      return inmuteChain(state)
      .set(`clients.${socketId}`, profile)
      .spy((state, { value }) => {
          const profile = JSON.stringify(value(`clients.${state.socket.id}.profile`));
          sessionStorage.setItem('profile', profile);
          localStorage.setItem('profile', profile);
          return state;
        })
        .value();
    }
    case ConnectionEvent.RoomSocketJoin: {
      const { roomId, clients, apps, profile } = action.payload;
      
      return inmuteChain(state)
        .set(`rooms.${roomId}.clients`, mapValues(clients, (client) => pick(client, ['isOwner'])))
        .set(`rooms.${roomId}.profile`, profile)
        .set(`rooms.${roomId}.apps`, apps)
        .set(`clients`, mapValues(clients, (client) => omit(client, ['isOwner'])))
        .value();
    }
    case ConnectionEvent.RoomProfileUpdate: {
      const { profile, roomId } = action.payload;
      
      return inmuteChain(state)
        .set(`rooms.${roomId}.profile`, profile)
        .value();
    }

    case ConnectionEvent.RoomSocketLeave: {
      const { roomId, client } = action.payload;
      const clients = omit(get(state, `rooms.${roomId}.clients`, {}), [client.socketId]);

      return inmuteSet(state, `rooms.${roomId}.clients`, clients, true);
    }

    case ConnectionEvent.ClientDisconnect: {
      const { socketId } = action.payload;

      return chainInmuteSet(state, Object.keys(state.rooms).map(roomId => ({
        path: `rooms.${roomId}.clients`,
        value: omit(get(state, `rooms.${roomId}.clients`, {}), [socketId]),
        force: true,
      })));
   }
    default:
      return state;
  }
};

const sessionProfile = sessionStorage.getItem('profile');
const localProfile = localStorage.getItem('profile');

export default function ConnectionProvider ({ children }: any) {

  const [state, dispatch] = useReducer(reducer, defaultValue);
  const { socket, emitter } = state;
  
  useEffect(() => {
    return () => {
      socket.close();
    };
  }, [socket]);
  
  useEffect(() => {
    if (!socket) return;
    socket.on('connect', () => {
      const profile = (() => {
        if (typeof sessionProfile === 'string') return JSON.parse(sessionProfile);
        if (typeof localProfile === 'string') return JSON.parse(localProfile);

      })();
      if (profile) {
        dispatch({
          type: ConnectionEvent.ClientProfileUpdate,
          payload: {
            socketId: socket.id,
            profile,
          },
        });
        socket.emit('profile/update', profile);
      }

    });

    socket.onAny((type: string, payload: any) => {
      if (type === 'nudge') {
        emitter.emit('nudge', payload);
        return;
      }
      dispatch({
        type,
        payload,
      })
    });

  }, [emitter, socket]);

  return (
    <context.Provider value={{ state, dispatch }}>
      {children}
    </context.Provider>
  )
}

export function useConnectionContext() {
  return useContext(context);
}

export function useConnectionQuick() {
  const { state: { rooms, defaultRoom } } = useContext(context);
  return null;
}

export function useConnectionApp(appIdArg?: string) {
  const { state: { socket } } = useContext(context);
  const { room, roomId }= useConnectionRoom();

  const appId = appIdArg || room.profile?.currentApp || '';

  const app = useMemo(() => {
    return room?.apps?.[appId];
  }, [room, appId]);

  const emit = useCallback((event: string, payload: any) => {
    socket.emit(`/room/app${event}`, {
      roomId,
      appId,
      ...payload,
    });
  }, [appId, roomId, socket]);

  
  return {
    app,
    emit,
  }

}

export function useConnectionRoom() {
  const { state: { socket, clients, rooms, defaultRoom } } = useContext(context);
  
  const roomId = defaultRoom;

  const room = useMemo(() => {
    return get(rooms, roomId ?? '', {}) as IRoom;
  }, [rooms, roomId]);

  const socketId = socket.id ?? '';

  const me = useMemo(() => {
    if (socketId) return (clients as any)[socketId];
  }, [clients, socketId]);

  const roomClients = useMemo(() => {
    return mapValues(room.clients, (partialClient, key: string) => {
      return {
        ...partialClient as {},
        ...(clients as any)[key],
      };
    });

  }, [clients, room.clients]);

  return {
    me,
    room,
    roomId,
    clients: roomClients,
  }
}

export function useConnectionEmitter(event: string, handler: (...args: any[]) => void) {
  const { state: { emitter } } = useContext(context);

  useEffect(() => {
    emitter.on(event, handler);
    return () => {
      emitter.off(event, handler);
    };
  }, [emitter, event, handler]);

  return emitter;
}