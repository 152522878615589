
import { Button, Chip, Input, TextInput } from "@mantine/core";
import { ConnectionEvent, useConnectionApp, useConnectionContext, useConnectionRoom } from "../../providers/ConnectionProvider";
import { useCallback, useMemo, useState } from "react";
import UserChip from "./UserChip";
import { QRCodeSVG } from "qrcode.react";


export default function RoomPanel() {
  const { dispatch, state: { socket } } = useConnectionContext();
  const { me, clients, roomId }= useConnectionRoom();
  const { emit }= useConnectionApp();

  if (me?.profile?.role !== 'screen') return null;

  const link = `${process.env.REACT_APP_BASE_URL || 'https://www.hanuji.com'}/go/${roomId}`;

  return (
    <>
      {Object.entries(clients || {}).map(([key, client]) => (
        client.profile?.role === 'player' && <UserChip key={key} clientId={key} />
      ))}
      <hr />
      <Button onClick={() => emit('/action', {
        command: 'restart',
      })} >Reiniciar</Button>
      {link}
      <QRCodeSVG value={link}  width={'250px'} height={'250px'} />
    </>
  );
}
