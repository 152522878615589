
import { Button, Chip } from "@mantine/core";
import { useConnectionContext } from "../../providers/ConnectionProvider";
import { useMemo } from "react";
import { useQuery } from "react-query";

interface AppSelectorProps {
  readonly value?: string;
  readonly onChange?: (appId: string) => void;
}

export default function AppSelector({ value, onChange }: AppSelectorProps) {
  const { state: { socket, clients } } = useConnectionContext();

  const { data: apps } = useQuery<any[]>({
    queryKey: 'apps',
    queryFn: async () => (await fetch(`${process.env.REACT_APP_API || 'https://api.hanuji.com'}/apps`)).json()
  })

  return (
    <>
      {(apps ?? []).map((app) => (
        <Button key={app.id} onClick={() => onChange?.(app.id)}>Start {app.id}</Button>
      ))}
    </>
  )
}
