import { useMemo } from "react";
import { useConnectionApp, useConnectionRoom } from "../../providers/ConnectionProvider";
import { Button } from "@mantine/core";
import DobbleCard from "../molecules/DobbleCard";

import './Dobble.scss';

export default function DobbleRender() {
  const { app, emit }= useConnectionApp('dobble');

  if (app.status.code === 'ready') {
    return (
      <div>
        <Button onClick={() => {
          emit('/action', {
            command: 'start',
          });
        }}>Start</Button>
      </div>
    )
  }

  if (app.cards) {
    return (
      <div className="dobble-render">
        {app.cards.map((card: any) => (
          <DobbleCard key={card.join('.')} card={card} onNumberClick={(number: any) => {
            emit('/action', {
              command: 'select-number',
              number,
            })
          }} />
        ))}
      </div>
    )
  } 

  return <div>Dobble! {app.status.code}</div>
};
