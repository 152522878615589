import { Routes, Route, Link } from "react-router-dom";
import ConnectionProvider from './providers/ConnectionProvider';
import { BrowserRouter } from "react-router-dom";

import './App.scss';
import Layout from "./components/organisms/Layout";
import PlayerRoom from "./components/views/PlayerRoom";
import { MantineProvider } from "@mantine/core";

import '@mantine/core/styles.css';
import OCR from "./components/views/OCR";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}


function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="go/:room" element={<PlayerRoom role="player" />} />
          <Route path="tv/:room" element={<PlayerRoom role="screen" />} />
          <Route path="ocr" element={<OCR />} />
          <Route path="about" element={<About />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function AppProvided() {
  return (
    <ConnectionProvider key="socket">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <MantineProvider>
            <App />
          </MantineProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ConnectionProvider>
  );
}


export default AppProvided;
