import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import Tesseract from 'tesseract.js';

export default function OCR() {
  const webcamRef = useRef<any>(); // create a webcam reference
  const [imgSrc, setImgSrc] = useState(null);
  const [text, setText] = useState('');

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  useEffect(() => {
    if (!imgSrc) return;
    Tesseract.recognize(
      imgSrc,'spa',
      { 
        logger: m => console.log(m) 
      }
    )
    .catch (err => {
      console.error(err);
    })
    .then((result: any) => {
      // Get Confidence score
      let confidence = result.confidence
     
      const { text } = result.data;
      setText(text);
  
    })
  }, [imgSrc, setText]);

  return (
    <div>
      <h2>OCR</h2>
      <Webcam height={600} width={600} ref={webcamRef} />
      {imgSrc && (
        <img height={300} width={400} src={imgSrc} alt="webcam" />
      )}
      <div className="btn-container">
        <button onClick={capture}>Capture photo</button>
      </div>
      {text}
    </div>
  );
}
