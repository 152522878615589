import { get, isArray, isObject, mergeWith, set } from "lodash";

export function inmuteSet<T>(state: T, path: string, value: any, force = false):T {
  const patch = set({}, path, value);

  const mergeFn = (a: any, b: any):any => {
    if (a === undefined) return b;
    if (b === undefined) return a;
    if (isObject(b) && !isArray(a)) return mergeWith({ ...a }, b, mergeFn);
    return b || a;
  }

  const inmuted = mergeWith({ ...state }, patch, mergeFn);

  return force ? set(inmuted, path, value) : inmuted;
};

export function chainInmuteSet<T> (state: T, patchs: { path: string, value: any, force?: boolean}[] = [] ):T {
  return patchs.reduce((prev, { path, value, force = false}) => inmuteSet(prev, path, value, force), state);

}

interface inmuteChainReturn<T, TForEach> {
  // get: (path: string, defValue?: any) => any;
  setIf: (condition: (value: T) => boolean, path: string, value: any, force?: boolean) => inmuteChainReturn<T, TForEach>;
  set: (path: string, value: any, force?: boolean) => inmuteChainReturn<T, TForEach>;
  forEach: (items: any[], handler: (item: TForEach, chainer: inmuteChainReturn<T, TForEach>) => any) => inmuteChainReturn<T, TForEach>;
  value: (path?: string, defValue?: any) => T | any;
  when: (condition: (value: T) => boolean, handler: (chainer: inmuteChainReturn<T, TForEach>) => any) => inmuteChainReturn<T, TForEach>;
  spy: (handler: (value: T, chainer: inmuteChainReturn<T, TForEach>) => T) => inmuteChainReturn<T, TForEach>;
}


export function inmuteChain<T, TForEach>(value: T): inmuteChainReturn<T, TForEach> {
  return {
    when(condition, handler) {
      const result = condition(value);
      if (result) {
        return handler(inmuteChain(value));
      }
      return inmuteChain(value);
    },
    spy(handler) {
      return inmuteChain(handler(value, inmuteChain(value)));
    },
    forEach(items, chainer) {
      return items.reduce((ret, item) => {
        return chainer(item, ret);
      }, inmuteChain(value));
    },
    set(path, setValue, force = false) {
      return inmuteChain(inmuteSet(value, path, setValue, force));
    },
    setIf(condition, path, setValue, force = false) {
      const result = condition(value);
      if (result) {
        return this.set(path, setValue, force);
      }
      return inmuteChain(value);
    },
    value: (path, defValue) => path ? get(value, path, defValue) : value,
    // get: (path, defValue) => get(value, path, defValue),
  };
}