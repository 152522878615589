import { useMemo } from "react";
import { useConnectionApp, useConnectionRoom } from "../../providers/ConnectionProvider";
import { Button } from "@mantine/core";



function Image({ number, onClick }: any) {
  const randomStyle = useMemo(() => {
    const rotate = Math.floor(Math.random() * 360);
    const scale = (90 + Math.floor(Math.random() * 20)) / 100;
  
    return {
      // transform: `rotate(${rotate}deg) scale(${scale})`,
    }
  }, []);

  return (
    <img
      style={randomStyle}
      onClick={onClick}
      alt={number.key}
      src={number.src} />
  );
}

export default function DobbleCard({ card, onNumberClick }: any) {


  const numbers = useMemo(() => {
    return card.map((number: string, index: number) => ({
      key: number,
      src: `https://assets.pokemon.com/assets/cms2/img/pokedex/detail/${`000${number}`.substr(-3)}.png`,
      transform: `rotate(${360 / (card.length - 1) * index}deg)`,
    }))
  }, [card]);

  return (
    <div className="dobble-card">
      {numbers.map((number: any) => (
        <div
          className="dobble-number"
          key={number.key}
          style={{
            transform: number.transform,
          }}
        >
          <Image
            onClick={() => onNumberClick(number.key)}
            number={number}
          />
        </div>
      ))}
    </div>
  );
};
