import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { AppShell, Burger, Container } from "@mantine/core";
import { useCallback, useState } from "react";
import { useConnectionEmitter } from "../../providers/ConnectionProvider";
import NicknameInput from "../molecules/NicknameInput";
import RoomPanel from "../molecules/RoomPanel";

import logoDobblemon from '../.././assets/dobblemon.png';
import logo from '../.././assets/logo.png';

export default function Layout() {
  const [opened, setOpened]  = useState(false);
  const [className, setClassName] = useState('shell');
  const [handler, setHandler] = useState<any>();

  useConnectionEmitter('nudge', useCallback(() => {
    clearInterval(handler);
    setClassName('shell nudged');
    setHandler(setInterval(() => setClassName('shell'), 100));
  }, [handler]));

  return (
    <AppShell
      className={className}
      header={{ height: { base: 60, sm: 60, lg: 60 } }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header>
        <Container className="header-container">
          <Burger
            hiddenFrom="sm"
            opened={opened}
            onClick={() => setOpened(!opened)}
            size="sm"
          />

          <img height="60" src={logo} alt="Hanuji" />
          <span className="logo-text">Hanuji</span>
        </Container>

      </AppShell.Header>

      <AppShell.Navbar p="md">
        <img width="200" className="logo-app" src={logoDobblemon} alt="Dobblemón" />
        <NicknameInput />
        <Sidebar />
        <RoomPanel />

      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
