
import { Chip } from "@mantine/core";
import { useConnectionApp, useConnectionContext } from "../../providers/ConnectionProvider";
import { useMemo } from "react";

interface UserChipProps {
  clientId: string;
}

export default function UserChip({ clientId }: UserChipProps) {
  const { state: { socket, clients } } = useConnectionContext();

  const { app }= useConnectionApp();

  const score = useMemo(() => {
    const player = app?.players?.find((player: any) => player.socketId === clientId);
    return player?.score || 0;
  }, [app?.players, clientId]);

  const client = useMemo(() => {
    return (clients as any)[clientId];
  }, [clientId, clients]);

  return (
    <div className="user-chip" onClick={() => socket.emit('nudge', client.socketId)}>
      <div>
        <span>👤</span>
        {client.profile?.nickname || client.socketId}
      </div>
      <div>
        {score}
      </div>
    </div>
  );
}
