
import { Chip, Input, TextInput } from "@mantine/core";
import { ConnectionEvent, useConnectionContext, useConnectionRoom } from "../../providers/ConnectionProvider";
import { useCallback, useEffect, useMemo, useState } from "react";


export default function NicknameInput() {
  const { dispatch, state: { socket } } = useConnectionContext();
  const { me, room, roomId }= useConnectionRoom();
  const [value, setValue] =  useState(me?.profile?.nickname || '');

  useEffect(() => {
    setValue(me?.profile?.nickname || '')
  }, [me?.profile?.nickname]);

  const syncNickname = useCallback((nickname: string) => {
    console.log(nickname)
    setValue(nickname);
    dispatch({
      type: ConnectionEvent.ClientProfileUpdate,
      payload: {
        socketId: socket.id,
        profile: {
          nickname,
        },  
      },
    });

    socket.emit('profile/update', {
      nickname,
    })
  }, [dispatch, socket]);

  if (me?.profile?.role !== 'player') return null;

  return (
    <TextInput
      label="Tu nombre:"
      placeholder="Nickname"
      value={value}
      onChange={(event) => syncNickname(event.target.value)}
      rightSectionPointerEvents="all"
      mt="md"
    />
  );
}
